<template>
  <div>
    <HeartysanSellerListingsHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">
      <HeartysanSellerListingsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <HeartysanSellerListingsTable
          v-if="navTabs.all.active"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="$emit('downloadFiles')"
      />

      <HeartysanSellerChatsTable
          v-if="navTabs.requests.active"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="$emit('downloadFiles')"
      />
    </div>
  </div>
</template>

<script>


  import HeartysanSellerListingsHead from "./HeartysanSellerListingsHead/HeartysanSellerListingsHead";
  import HeartysanSellerListingsFilter from "./HeartysanSellerListingsFilter/HeartysanSellerListingsFilter";
  import HeartysanSellerListingsTable from "./HeartysanSellerTables/HeartysanSellerListingsTable/HeartysanSellerListingsTable";
  import HeartysanSellerChatsTable
    from "@/components/modules/HeartysanModule/components/HeartysanSeller/HeartysanSellerListings/HeartysanSellerListingsBlock/HeartysanSellerTables/HeartysanSellerChatsTable/HeartysanSellerChatsTable";

  export default {
    name: "HeartysanSellerListingsBlock",

    components: {
      HeartysanSellerChatsTable,
      HeartysanSellerListingsHead,
      HeartysanSellerListingsFilter,
      HeartysanSellerListingsTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
