<template>
  <div class="fragment">
    <HeartysanSellerListingsBlock
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @reload="reload"
    />


  </div>

</template>

<script>
  import HeartysanSellerListingsBlock from "./HeartysanSellerListingsBlock/HeartysanSellerListingsBlock";
  import {routeFilter} from "../../../../../../mixins/routeFilterMixins/routeFilter";
  import {HEARTYSAN_STATUSES} from "@/staticData/staticVariables";

  export default {
    name: "HeartysanSellerListings",
    components: {
      HeartysanSellerListingsBlock,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getHeartysanProductsForPage,
        page: 1,
        onePage: 1,
        filterType: 'all',
        filterDate: [],
        filterId: '',
        filterName: '',
        filterStatus: '',
        filterPrice: '',
        filterUserName: '',
        filterUserId: '',
        filterCategoryId: '',
        filterCategoryName: '',
        filterPriceFrom: '',
        filterPriceTo: '',

        filterCode: '',
        filterItemNumber: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          all: {
            label: this.$t('heartysan_AllListings.localization_value.value'),
            name: 'all',
            id: 1,
            active: true,
            // permissionVisible: true,
          },
          requests: {
            label: this.$t('heartysan_requestsProducts.localization_value.value'),
            name: 'requests',
            id: 2,
            active: false,
          },
        },
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          type: this.$route.query.type,
          id: this.$route.query.id,
          name: this.$route.query.name,
          status: this.$route.query.status,
          price: this.$route.query.price,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
          categoryId: this.$route.query.categoryId,
          categoryName: this.$route.query.categoryName,
          priceFrom: this.$route.query.priceFrom,
          priceTo: this.$route.query.priceTo,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if(this.navTabs.requests.active) {
          if (next) this.$store.commit('setNextHeartysanChatPage', true)
          this.$store.dispatch('fetchHeartysanChat', url).then(() => {
            this.$store.commit('setNextHeartysanChatPage', false)
          })

          this.checkCountFilter(['userName'])
          return
        }

        if (next) this.$store.commit('setNextHeartysanProductsPage', true)
        this.$store.dispatch('fetchHeartysanProducts', url).then(() => {
          this.$store.commit('setNextHeartysanProductsPage', false)
        })


        this.checkCountFilter(['userName', 'type', 'categoryName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if (this.navTabs.all.active) {

          this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'id',
              filterUserId: 'user_id',
              filterCategoryId: 'parentCategoryId',
              filterName: 'name',
              filterPriceFrom: 'priceFrom',
              filterPriceTo: 'priceTo',
            },
          )

          myQuery.where('enable', 1)
          myQuery.where('status', HEARTYSAN_STATUSES.HEARTYSAN_STATUS_ACTIVE.value)

        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

    }

  }
</script>

<style scoped>

</style>
