<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter">
    <template slot="body">

      <div class="table-filter__item">
        <DefaultInput
            :label="'Id'"
            :type="'text'"
            v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_name'])"></div>
        <DefaultInput
            :label="$t('heartysan_name.localization_value.value')"
            :type="'text'"
            v-model="name"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_priceFrom'])"></div>
        <DefaultInput
            :label="$t('heartysan_priceFrom.localization_value.value')"
            :type="'text'"
            v-model="priceFrom"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_priceTo'])"></div>
        <DefaultInput
            :label="$t('heartysan_priceTo.localization_value.value')"
            :type="'text'"
            v-model="priceTo"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_user',])"></div>
        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearch"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
            @change="changeUsersFilter"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['heartysan_category'])"></span>
        <DefaultSelect
            :options="optionsCategory"
            :otherValue="'currentTranslation'"
            :label="$t('heartysan_category.localization_value.value')"
            @change="changeCategory"
            :selected="categoryId ? {
              id: categoryId,
              currentTranslate: {name: categoryName}
            } : null"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['heartysan_subCategory'])"></span>

        <DefaultSelect
            :options="optionsSubCategory"
            :otherValue="'currentTranslation'"
            :label="$t('heartysan_subCategory.localization_value.value')"
            @change="changeSubCategory"
            :selected="subCategoryId ? {
              id: subCategoryId,
              currentTranslate: {name: subCategoryName}
            } : null"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "@/mixins/usersMixins/usersFunc";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "HeartysanSellerListingsFilter",
    components: {
      DefaultSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc, queryFilterMixin],

    data(){
      return {

        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        name: this.filterGetParams.contactName ? this.filterGetParams.contactName : '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',
        price: this.filterGetParams.price ? this.filterGetParams.price : '',
        priceFrom: this.filterGetParams.priceFrom ? this.filterGetParams.priceFrom : '',
        priceTo: this.filterGetParams.priceTo ? this.filterGetParams.priceTo : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        categoryId: this.filterGetParams.categoryId ? this.filterGetParams.categoryId : '',
        categoryName: this.filterGetParams.categoryName ? this.filterGetParams.categoryName : '',

        subCategoryId: '',
        subCategoryName: '',

        options: [],

        categories: [],
        optionsCategory: [],
        optionsSubCategory: [],

        filterCounts: [
          'date',
          'id',
          'name',
          'status',
          'price',
          'priceFrom',
          'priceTo',

          'categoryId',
          'categoryName',
          'userName',
          'userId',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.name = newVal.name ? newVal.name : ''
        this.status = newVal.status ? newVal.status : ''
        this.price = newVal.price ? newVal.price : ''
        this.priceFrom = newVal.priceFrom ? newVal.priceFrom : ''
        this.priceTo = newVal.priceTo ? newVal.priceTo : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.categoryId = newVal.categoryId ? newVal.categoryId : ''
        this.categoryName = newVal.categoryName ? newVal.categoryName : ''

        this.checkSubCategory()
      },

    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        // filterCountriesUSA(countries)
        this.optionsCountries = countries;
      })
      let myQuery = this.createQueryBuilderObject()
      let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
      this.$store.dispatch('fetchHeartysanCategory', url).then(response => {
        this.categories = this.getRespPaginateData(response)
        this.checkSubCategory()
      }).catch(error => this.createErrorLog(error))


    },

    methods: {
      checkSubCategory() {

        this.optionsCategory = this._.filter(this.categories, (item) => {
          return item.parent_id === 0
        })

        let findCategory = this._.find(this.categories, {id: parseInt(this.categoryId)})

        if(findCategory) {
          if(findCategory.parent_id !== 0){
            this.subCategoryId = this.categoryId
            this.subCategoryName = this.categoryName

            let parent = this._.find(this.categories, {id: parseInt(findCategory.parent_id)})
            if(parent){
              this.categoryId = parent.id
              this.categoryName = parent.name
            }
          } else {
            this.optionsSubCategory = this._.filter(this.categories, (item) => {
              return item.parent_id === parseInt(this.categoryId)
            })
          }
        }
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        if(this.subCategoryId) {
          this.subCategoryId = ''
          this.subCategoryName = ''
        }

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        if(this.subCategoryId) {
          data.categoryId = this.subCategoryId
          data.categoryName = this.subCategoryName
        }

        this.$emit('changeFilter', data)
      },

      changeCategory(val) {
        this.categoryId = val.id
        this.categoryName = val.name

        this.subCategoryId = ''
        this.subCategoryName = ''

        this.optionsSubCategory = this._.filter(this.categories, (item) => {
          return item.parent_id === val.id
        })
      },

      changeSubCategory(val) {
        this.subCategoryId = val.id
        this.subCategoryName = val.name
      },
    },
  }
</script>

<style scoped>

</style>
