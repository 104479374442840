var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"content-top-line content-top-line--with-bottom-line",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        // 'ukrTreasures_AllListings',
        // 'ukrTreasures_ActiveProducts',
        // 'ukrTreasures_SoldProducts',
        // 'ukrTreasures_PayPal',
      ])}}}),_c('div',{staticClass:"content-tabs content-tabs--separator"},[_vm._l((_vm.navTabs),function(item,index){return [_c('div',{key:index,staticClass:"content-tabs__item",class:{active: item.active},on:{"click":function($event){return _vm.$emit('changeTab', item.name)}}},[_c('div',{staticClass:"content-tabs__ico"},[_vm._v(" "+_vm._s(item.label)+" ")])])]})],2),_c('div',{staticClass:"content-top-line-wrap"},[_c('ToggleFilterButton',{attrs:{"count":_vm.countFilterParams,"showFilter":_vm.showFilter},on:{"toggleFilter":function($event){return _vm.$emit('toggleFilter')}}})],1)]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"light-bg-block"},[_c('div',{staticClass:"btn-left-block"}),_c('div',{staticClass:"btn-right-block"})])
}]

export { render, staticRenderFns }