<template>
  <div class="fragment" :key="$store.getters.getHeartysanProductsEvents + $store.getters.getHeartysanProducts.length">
    <template v-if="$store.getters.getHeartysanProducts.length > 0 && $store.getters.getHeartysanProductsLoading !== true">
      <div class="custom-row table-top-padding">
        <template
            v-for="(item, index) in $store.getters.getHeartysanProducts">
          <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
               v-if="item.status === 'active' && item.enable"
               :key="index">
            <div class="case-cart">
              <div class="case-cart__inner">
                <div class="case-cart__columns">
                  <div class="case-cart__img-block">
                    <div class="case-cart__img">
                      <img v-if="item.imageBase64" :src="'data:image/png;base64,' + item.imageBase64" alt="img">
                      <img v-else src="/img/finance-group/list-no-image.svg" alt="img">
                    </div>
                    <div class="case-cart__number-block">
                    </div>
                  </div>
                  <div class="case-cart__content-block">
                    <div class="case-cart__head pl-0">
                      <router-link class="case-cart__title case-cart__title--status" :to="$store.getters.GET_PATHS.heartysanSellerListings + '/' + item.id">
                        <TooltipTextHelper>
                          <template slot="text">
                            <div class="case-cart__content--description">{{item.name}}</div>
                          </template>
                          <template slot="paragraph">
                            {{item.name}}
                          </template>
                        </TooltipTextHelper>
                      </router-link>
                    </div>
                    <div class="case-cart__body pl-0">
                      <div class="case-cart__row custom-row">
                        <TableUserNameRow
                            v-if="isAdmin"
                            :item="item"
                            :colWidth="'custom-col--66'"
                        />
                        <div class="case-cart__col custom-col--33">
                          <div class="case-cart__label"
                               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['common_id'])"></div>
                            {{$t('common_id.localization_value.value')}}
                          </div>
                          <div class="case-cart__content">
                            {{ item.id }}
                          </div>
                        </div>
                        <div class="case-cart__col custom-col custom-col--33">
                          <div class="case-cart__label"
                               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['heartysan_price'])"></div>
                            {{$t('heartysan_price.localization_value.value')}}
                          </div>
                          <div class="case-cart__content">
                            ${{item.price}}
                          </div>
                        </div>
                        <div class="case-cart__col custom-col">
                          <div class="case-cart__label"
                               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['heartysan_quantity'])"></div>
                            {{$t('heartysan_quantity.localization_value.value')}}
                          </div>
                          <div class="case-cart__content">
                            {{item.production_terms}}
                          </div>
                        </div>
                        <div class="case-cart__col custom-col custom-col">
                          <div class="case-cart__label"
                               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['heartysan_description'])"></div>
                            {{$t('heartysan_description.localization_value.value')}}
                          </div>
                          <div class="case-cart__content">
                            <TooltipTextHelper>
                              <template slot="text">
                                <div class="case-cart__content--description">{{item.description}}</div>
                              </template>
                              <template slot="paragraph">
                                {{item.description}}
                              </template>
                            </TooltipTextHelper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="case-cart__bottom"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['heartysan_request'])"></div>
                  <div class="case-cart__btn ml-auto">
                  </div>
                  <div class="case-cart__btn ml-auto"
                       v-if="!isAdmin && _.find(item.chats, {seller_user_id: getCurrentUser.id})">
                    <router-link :to="$store.getters.GET_PATHS.heartysanChat + `?chatId=${_.find(item.chats, {seller_user_id: getCurrentUser.id}).id}&productId=${item.id}`">
                      <CaseCartButton
                          :value="$t('heartysan_goToChat.localization_value.value')"
                          :ico="true"
                          :typeIco="'edit'"
                      >
                      </CaseCartButton>
                    </router-link>

                    <!--                      @click.native="requestProduct(item)"-->
                  </div>
                  <div class="case-cart__btn ml-auto"
                       v-else-if="!isAdmin">
                    <CaseCartButton
                        :value="$t('heartysan_request.localization_value.value')"
                        :ico="true"
                        :typeIco="'edit'"
                        @click.native="requestProduct(item)"
                    >
                    </CaseCartButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getHeartysanProductsCommonList.next_page_url !== null && $store.getters.getHeartysanProducts.length > 0"
              @click.native="$emit('showMore')"
              :count="$store.getters.getHeartysanProductsCommonList.total - $store.getters.getHeartysanProductsForPage < $store.getters.getHeartysanProductsForPage ?
                  $store.getters.getHeartysanProductsCommonList.total - $store.getters.getHeartysanProductsForPage:
                  $store.getters.getHeartysanProductsForPage"
          />
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getHeartysanProductsLoading === false && $store.getters.getHeartysanProducts.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
  import ShowMore from "../../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../../../mixins/mobileFunctions'
  import NoResult from "../../../../../../../../coreComponents/NoResult/NoResult";
  import CaseCartButton from "@/components/UI/buttons/CaseCartButton/CaseCartButton";
  import TableUserNameRow from "@/components/coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";
  import {heartysanProductsMixin} from "@/mixins/heartysanMixins/heartysanProductsMixin";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "HeartysanSellerListingsTable",
    components: {
      TooltipTextHelper,
      TableUserNameRow,
      CaseCartButton,
      NoResult,
      ShowMore,
    },

    mixins: [mixinDetictingMobile, heartysanProductsMixin],

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
      }
    },

    methods: {




    }
  }
</script>

<style scoped lang="scss">

.table-top-padding {
  padding-top: 24px;
}

.case-cart__content--description{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
