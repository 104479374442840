<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          // 'ukrTreasures_AllListings',
          // 'ukrTreasures_ActiveProducts',
          // 'ukrTreasures_SoldProducts',
          // 'ukrTreasures_PayPal',
        ])"></div>
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
            :count="countFilterParams"
            @toggleFilter="$emit('toggleFilter')"
            :showFilter="showFilter"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">

      </div>

      <div class="btn-right-block">

      </div>
    </div>


  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";

  export default {
    name: "HeartysanSellerListingsHead",
    components: {
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data(){
      return{

      }
    },

    methods: {

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../../scss/mixins/mixins";


</style>

