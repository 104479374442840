<template>
  <div class="detail-page">
<!--    <div class="detail-page__left"-->
<!--         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--      <div class="admin-edit" @click="editTranslate(['heartysan_price'])"></div>-->
<!--      <CardLeftBlock-->
<!--          :name="''"-->
<!--          :value="HL.data.name"-->
<!--          :backgroundImage="'product-admin'"-->
<!--          class="cus-payments"-->
<!--      >-->
<!--&lt;!&ndash;        <template slot="body">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="card-detail-left__date mt-1">&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </template>&ndash;&gt;-->
<!--        <template slot="button">-->
<!--          <h2 class="mt-0">{{$t('heartysan_price.localization_value.value')}} : ${{HL.data.price}}</h2>-->
<!--        </template>-->
<!--      </CardLeftBlock>-->
<!--    </div>-->

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head mb-3">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['heartysan_backTo'])"></div>
                <router-link :to="$store.getters.GET_PATHS.heartysanSellerListings" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('heartysan_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
<!--            <div class="order-create__head-title"-->
<!--                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['ukrTreasures_ListingToSell'])"></div>-->
<!--              {{ $t('heartysan_CreateListing.localization_value.value') }}-->
<!--            </div>-->
<!--            <div class="order-create__head-txt mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['ukrTreasures_PleaseFillOut'])"></div>-->
<!--              {{ $t('heartysan_CreateListingPleaseFill.localization_value.value') }}-->
<!--            </div>-->
          </div>
        </template>

        <template slot="body">

          <ListingShowBlock
              :HL="HL"
              :loadedImage="loadedImage"
              :chat="getRouteChat"
              :sent="!isAdmin && getCurrentUser && !_.find(HL.data.chats, {seller_user_id: getCurrentUser.id})"
              @requestProduct="requestProduct"
          />


        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.heartysanSellerListings"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>

            </div>

<!--            <div class="order-create__footer-btn ml-auto"-->
<!--                 v-if="!isAdmin && getCurrentUser && _.find(HL.data.chats, {seller_user_id: getCurrentUser.id})"-->
<!--                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['heartysan_goToChat'])"></div>-->
<!--              <MainButton-->
<!--                  class="order-create__footer-btn-i wfc"-->
<!--                  :value="$t('heartysan_goToChat.localization_value.value')"-->
<!--                  @click.native="$emit('save')"-->
<!--                  v-bind:class="{'disabled-btn' : $store.getters.heartysanProductsBtn}"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="order-create__footer-btn ml-auto"-->
<!--                 v-if="!isAdmin && getCurrentUser && !_.find(HL.data.chats, {seller_user_id: getCurrentUser.id})"-->
<!--                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['heartysan_request'])"></div>-->
<!--              <MainButton-->
<!--                  class="order-create__footer-btn-i wfc"-->
<!--                  :value="$t('heartysan_request.localization_value.value')"-->
<!--                  @click.native="requestProduct({user_id: HL.data.manufacturerId, id: HL.data.id})"-->
<!--                  v-bind:class="{'disabled-btn' : $store.getters.heartysanProductsBtn}"-->
<!--              />-->
<!--            </div>-->
          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
// import CardLeftBlock from "../../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
// import MainButton from "../../../../../../../components/UI/buttons/MainButton/MainButton";
import CardRightBlock from "../../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../../UI/buttons/LinkBack/LinkBack.vue";
import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
import ListingShowBlock from "@/components/modules/HeartysanModule/components/chunks/ListingShowBlock/ListingShowBlock";
import {heartysanProductsMixin} from "@/mixins/heartysanMixins/heartysanProductsMixin";


export default {
  name: "HeartysanSellerListingShowUser",

  components: {
    ListingShowBlock,
    // CardLeftBlock,
    // MainButton,
    CardRightBlock,
    LinkBack,
  },

  mixins: [mixinDetictingMobile, heartysanProductsMixin],

  props: {
    HL: Object,
    loadedImage: Boolean,
  },

  computed: {
    getRouteChat() {
      let chat = this._.find(this.HL.data.chats, {seller_user_id: this.getCurrentUser?.id})
      if(!this.isAdmin && chat)
        return this.$store.getters.GET_PATHS.heartysanChat + `?chatId=${chat.id}&productId=${this.HL.data.id}`
      return null
    },
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped>
/*@media (min-width: 1600px) {*/
/*  .detail-page__right{*/
/*    max-width: 70%;*/
/*  }*/
/*}*/
</style>

