<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup shipping-company-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['dashboard_ImportEtsyListings'])"></div>
        {{ $t('heartysan_chooseEtsyListing.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="shipping-company-popup__body">
          <div class="shipping-company-popup__content">
            <div class="custom-row">
              <div class="custom-col mb-3"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['heartysan_EtsyShops'])"></div>
                <DefaultSelect
                    v-bind:class="{'ui-no-valid': etsyShopValidErr}"
                    :error="etsyShopValidErr"
                    :errorTxt="$t(`error_required.localization_value.value`)"
                    :options="options"
                    :label="$t('heartysan_EtsyShops.localization_value.value')"
                    :optionsLabel="'shop_name'"
                    @change="changeEtsyShops"
                />
              </div>
            </div>
          </div>

        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn">
        <MainButton
            class="custom-popup__btn-i wfc"
            :value="$t('heartysan_chooseShop.localization_value.value')"
            @click.native="chooseEtsyShop"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "ChooseShopEtsyListing",
  components: {
    DefaultSelect,
    Modal,
    MainButton,
  },

  props: {
    dealId: Number,
    productId: Number,
  },

  data() {
    return {
      options: [],
      etsyShop: {},
      etsyShopValidErr: false,
    }
  },

  mounted() {
    const query = new this.Query();
    let url = '?';
    let myQuery = query
        .for('posts')

    url = url + myQuery.limit(100000).page(1).url().split('?')[1]

    this.$store.dispatch('getHeartysanEtsyShops', url).then(response => {
      this.options = this.getRespData(response)
    })
  },

  methods: {
    changeEtsyShops(val) {
      this.etsyShop = val
    },

    chooseEtsyShop() {
      if(!this.etsyShop?.id) {
        this.etsyShopValidErr = true
        return
      } else {
        this.etsyShopValidErr = false
      }
      this.$router.push(this.$store.getters.GET_PATHS.heartysanEtsyListings + `/${this.etsyShop?.id}/create?dealId=${this.dealId}&productId=${this.productId}`)
    },

  }
}
</script>

<style scoped lang="scss">

@import "../../../../../scss/colors";
@import "../../../../../scss/mixins/mixins";

.shipping-company-popup {

  &__body {
    display: flex;

    @include for-680 {
      flex-direction: column;
    }
  }

  &__content {
    width: 100%;

    @include from-680 {
      padding-right: 20px;
    }
  }

  &__file {
    max-width: 105px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @include for-680 {
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-bottom: 35px;
    }

    input[type="file"] {
      display: none;
    }
  }

  &__file-label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &__file-img {
    display: flex;
    flex-direction: column;

    img {
      width: 105px;
      height: 105px;
      object-fit: contain;
    }
  }

  &__file-text {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $brown;
  }

}

.custom-popup__btn {

}

.custom-popup__btn-i {
  margin-right: 10px;
  min-width: 122px;
  width: fit-content;
  margin-bottom: 10px;

  &.secondary {
    border-width: 2px;
  }
}
</style>
